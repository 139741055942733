
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"

import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"

const Page = ({ pageContext }) => {
  const {
    page: { title, content },
  } = pageContext

  return (
    <Layout>


      <section className="content fadeIn visible">
   <div className="eighty-spacer"></div>
   <div className="content-block">
      <div className="centered-content-container">
                <div className="centered-intro-title-holder">
                <SEO title={title}/>
               <h1> {title} </h1>
            </div>
            <div className="centered-intro-text-holder no-bottom-pad">
               <div dangerouslySetInnerHTML={{ __html: content }}/>
            </div>
               </div>
   </div>
   <div class="eighty-spacer"></div>
</section>
    </Layout>

  )
}

export default Page
  